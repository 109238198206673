export const LedgerType = {
    ASSET: 'ASSET',
    LIABILITY: 'LIABILITY',
    REVENUE: 'REVENUE',
    EXPENSE: 'EXPENSE',
};
export const LedgerTransactionType = {
    COLLECTION: 'COLLECTION',
    COLLECTION_ON_BEHALF: 'COLLECTION_ON_BEHALF',
    EXCESS_COLLECTION: 'EXCESS_COLLECTION',
    REVENUE_RECOGNITION: 'REVENUE_RECOGNITION',
    PAYOUT: 'PAYOUT',
    REFUND: 'REFUND',
    EXCESS_REFUND: 'EXCESS_REFUND',
};
export const ActiveSgCreditTransactionType = {
    PAYMENT: 'PAYMENT',
    CREDIT: 'CREDIT',
    EXPIRED: 'EXPIRED',
    REFUND: 'REFUND',
    DEDUCTION: 'DEDUCTION',
};
export const BookingStatus = {
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    CANCELLED_WITHOUT_REFUND: 'CANCELLED_WITHOUT_REFUND',
    CANCELLED_WITH_REFUND: 'CANCELLED_WITH_REFUND',
};
export const BallotStatus = {
    PENDING: 'PENDING',
    FAILED: 'FAILED',
    SELECTED: 'SELECTED',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED',
};
export const FacilityStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};
export const UserStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};
export const Sex = {
    MALE: 'MALE',
    FEMALE: 'FEMALE',
    UNKNOWN: 'UNKNOWN',
};
export const ResidentialStatus = {
    ALIEN: 'ALIEN',
    CITIZEN: 'CITIZEN',
    PR: 'PR',
};
export const BlacklistType = {
    SUSPENSION: 'SUSPENSION',
    TERMINATION: 'TERMINATION',
};
export const TempPrincipalRole = {
    READ_PASS: 'READ_PASS',
    SUPER_ADMIN: 'SUPER_ADMIN',
    CONSUME_PASS: 'CONSUME_PASS',
};
export const MemberLinkRelation = {
    CHILD: 'CHILD',
    OTHER: 'OTHER',
};
export const MembershipStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};
export const TimeRestriction = {
    PEAK_ONLY: 'PEAK_ONLY',
    OFF_PEAK_ONLY: 'OFF_PEAK_ONLY',
    ALL: 'ALL',
};
export const PassCheckoutSessionStatus = {
    NONE: 'NONE',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
};
export const PaymentStatus = {
    NONE: 'NONE',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    CANCELLED: 'CANCELLED',
};
export const RefundStatus = {
    REVIEWING: 'REVIEWING',
    CREATING: 'CREATING',
    PENDING: 'PENDING',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
    CANCELLED: 'CANCELLED',
    REJECTED: 'REJECTED',
};
export const ProgrammeBookingStatus = {
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    CANCELLED: 'CANCELLED',
};
export const ProgrammeSessionBookingStatus = {
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    CANCELLED_WITH_REFUND: 'CANCELLED_WITH_REFUND',
    CANCELLED_WITHOUT_REFUND: 'CANCELLED_WITHOUT_REFUND',
};
export const ActivityStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};
export const NotificationChannel = {
    EMAIL: 'EMAIL',
    SMS: 'SMS',
};
export const NotificationKey = {
    BOOKING: 'BOOKING',
    PASS: 'PASS',
    PROGRAMME: 'PROGRAMME',
};
export const SerialType = {
    TRANSACTION: 'TRANSACTION',
    RECEIPT: 'RECEIPT',
    REFUND: 'REFUND',
};
