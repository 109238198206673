import {
  Box,
  chakra,
  FormControl,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
} from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  PhoneNumberInput,
  useIsMobile,
} from '@opengovsg/design-system-react'
import { Controller, useFormState } from 'react-hook-form'

import { toTitleCase } from '@activesg/common/utilities'

import { useMe } from '~/features/me/api'
import { useVerifyMobile } from '../../VerifyMobileProvider'

export const EnterMobileContent = (): JSX.Element => {
  const { me } = useMe()
  const isMobile = useIsMobile()

  const { isLoading, handleSendOtp, mobileFormProps } = useVerifyMobile()

  const { control } = mobileFormProps

  const { isSubmitting, errors } = useFormState({ control })

  return (
    <>
      <ModalHeader pr="5rem">Welcome, {toTitleCase(me.name)}</ModalHeader>
      <chakra.form
        noValidate
        display="flex"
        flex={1}
        flexDirection="column"
        onSubmit={handleSendOtp}
      >
        <ModalBody flex={1} whiteSpace="pre-wrap">
          <Stack spacing="1rem">
            <Text>
              To continue, enter your mobile number for verification. You can
              still change this in your profile after.
            </Text>
            <FormControl
              isRequired
              id="mobile"
              isInvalid={!!errors.mobile}
              isReadOnly={isSubmitting}
            >
              <FormLabel>Mobile number</FormLabel>
              <Controller
                control={control}
                name="mobile"
                render={({ field }) => <PhoneNumberInput {...field} />}
              />
              {errors.mobile ? (
                <FormErrorMessage>{errors.mobile.message}</FormErrorMessage>
              ) : (
                <Box my="0.5rem">
                  <FormHelperText>
                    A 6-digit code will be sent to this mobile number
                  </FormHelperText>
                </Box>
              )}
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack
            direction={{ base: 'column', md: 'row-reverse' }}
            spacing="0.75rem"
            w="100%"
          >
            <Button isFullWidth={isMobile} isLoading={isLoading} type="submit">
              Get code
            </Button>
          </Stack>
        </ModalFooter>
      </chakra.form>
    </>
  )
}
