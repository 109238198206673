import Head from 'next/head'
import { Flex } from '@chakra-ui/react'

import { useEnv } from '@activesg/ui/hooks'
import { type GetLayout } from '@activesg/ui/types'

export const DefaultLayout: GetLayout = (page) => {
  const { env } = useEnv()
  return (
    <Flex flex={1} flexDir="column">
      <Head>
        <title>{env.NEXT_PUBLIC_APP_NAME}</title>
        <link href="/favicon.ico" rel="icon" />
      </Head>
      {page}
    </Flex>
  )
}
