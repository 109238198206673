import {
  FACILITY_BALLOTING_ROUTES,
  FACILITY_BOOKING_ROUTES,
} from '@activesg/common/routes/member'

export const SIGN_IN = '/sign-in'
export const HOME = '/home'
export const PROFILE = '/profile'
export const SETTINGS = '/settings'
export const SUSPENDED = '/suspended'
export const TERMINATED = '/terminated'
export const GYM_CAPACITY = '/gym-capacity'

export const ACTIVITIES_ROOT = '/activities'
export const ACTIVITIES_LIST = `${ACTIVITIES_ROOT}/list`

// TODO: Update all routes to use the new routes
export const BOOKINGS = FACILITY_BOOKING_ROUTES.root()
export const BOOKING_CONFIRMATION =
  FACILITY_BOOKING_ROUTES.confirmation().root()

export const BALLOTS = FACILITY_BALLOTING_ROUTES.root()
export const BALLOT_CONFIRMATION =
  FACILITY_BALLOTING_ROUTES.confirmation().root()

export const PROGRAMMES_ROOT = '/programmes'
