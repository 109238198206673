// Setup dayjs
// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import duration from 'dayjs/plugin/duration.js';
import isoWeek from 'dayjs/plugin/isoWeek.js';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js';
import localeData from 'dayjs/plugin/localeData.js';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc.js';
import { TIMEZONE } from '@activesg/constants';
import toLocalDateOnly from './plugins/toLocalDateOnly.js';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.tz.setDefault(TIMEZONE);
// Custom plugins
dayjs.extend(toLocalDateOnly);
export { dayjs, utc, timezone, isSameOrAfter, isSameOrBefore, isoWeek, duration, relativeTime, customParseFormat, localeData, toLocalDateOnly, };
