import { z } from 'zod';
export const payableEntitySchema = z.enum([
    'Booking',
    'Pass',
    'ProgrammeSessionBooking',
]);
export const stripeBalanceTransactionSchema = z
    .object({
    fee: z.number(),
})
    .strip();
const stripePaymentMethodDetailsSchema = z
    .object({
    type: z.string(),
})
    .strip();
export const stripeRefundSchema = z.object({
    id: z.string(),
    object: z.literal('refund'),
    amount: z.number(),
    metadata: z.record(z.string()),
    created: z.number(),
    charge: z.string().nullable(),
    balance_transaction: z.union([
        z.null(),
        z.string(),
        stripeBalanceTransactionSchema,
    ]),
    status: z.enum([
        'pending',
        'requires_action',
        'succeeded',
        'failed',
        'canceled',
    ]),
});
export const stripeChargeSchema = z
    .object({
    id: z.string(),
    object: z.literal('charge'),
    amount: z.number(),
    balance_transaction: z.union([
        z.null(),
        z.string(),
        stripeBalanceTransactionSchema,
    ]),
    created: z.number(),
    metadata: z.record(z.string()),
    payment_method: z.string().nullable(),
    payment_method_details: stripePaymentMethodDetailsSchema.nullable(),
})
    .strip();
// this metadata field contains arrays of entity, entityId, description etc.
// however, we don't include them in the schema as they could be truncated
// thus, we always retrieve such information from our database
export const stripePaymentIntentMetadata = z
    .object({
    paymentId: z.string(),
})
    .strip();
export const stripePaymentIntentSchema = z
    .object({
    id: z.string(),
    object: z.literal('payment_intent'),
    amount: z.number(),
    latest_charge: z.union([z.null(), z.string(), stripeChargeSchema]),
    metadata: stripePaymentIntentMetadata,
})
    .strip();
export const stripePayoutSchema = z
    .object({
    id: z.string(),
    object: z.literal('payout'),
    amount: z.number(),
})
    .strip();
// this metadata field contains arrays of entity, entityId, description etc.
// however, we don't include them in the schema as they could be truncated
// thus, we always retrieve such information from our database
export const stripeRefundMetadataSchema = z.object({
    refundId: z.string(),
});
const stripePaymentIntentMetadataItemsSchema = z.array(z.object({
    entity: payableEntitySchema,
    title: z.string().nullish(),
    entityId: z.string(),
    description: z.string(),
    priceInCents: z.string(),
    creditsUsedInCents: z.string().nullable(),
}));
// conversion before sending data to Stripe
export function convertItemsToStripePaymentItemMetadata(items) {
    const metadata = {
        entities: items.map((item) => item.entity),
        entityIds: items.map((item) => item.entityId),
        titles: items.map((item) => item.title ?? ''),
        descriptions: items.map((item) => item.description),
        priceInCentsArray: items.map((item) => item.priceInCents),
        creditsUsedInCentsArray: items.map((item) => item.creditsUsedInCents),
    };
    return convertToStripeMetadataObject(metadata);
}
// we use this schema to validate data before we store in database
export const stripeRefundMetadataItemsSchema = z.array(z.object({
    entity: payableEntitySchema,
    entityId: z.string(),
    description: z.string(),
    amount: z.string(),
    paymentItemId: z.string(),
}));
// conversion before sending data to Stripe
export function convertItemsToStripeRefundMetadata(items) {
    const metadata = {
        entities: items.map((item) => item.entity),
        entityIds: items.map((item) => item.entityId),
        descriptions: items.map((item) => item.description),
        amounts: items.map((item) => item.amount),
        paymentItemIds: items.map((item) => item.paymentItemId),
    };
    return convertToStripeMetadataObject(metadata);
}
// this function enforces Stripe metadata limits
export function convertToStripeMetadataObject(obj) {
    // Stripe allows max 50 keys in metadata object
    // throw error at 40 as there are other metadata fields (paymentId, refundId) that should take priority
    if (Object.keys(obj).length > 40) {
        throw new Error('Too many keys in object');
    }
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [
        // each metadata key can be at most 40 characters long
        key.slice(0, 40),
        // Stripe has a limit of 500 characters per metadata key.
        // we will also truncate any field that is longer than 500 characters
        // as such, for programme booking with more than 23 sessions, there will be truncation in entityIdArray
        JSON.stringify(value).slice(0, 500),
    ]));
}
/** For payments out of system that share the same stripe account
 *
 * e.g Payment Links or Stripe Terminal
 */
export const OutOfSystemMetadataSchema = z.object({
    isOutOfSystem: z.enum(['true']),
});
export const CheckoutSessionMetadataSchema = OutOfSystemMetadataSchema.merge(z.object({
    isCheckoutSession: z.enum(['true']),
    checkoutSessionId: z.string(),
}));
/** To identify if event is from payment link. We handle these differently in webhook */
export const CheckoutSessionDataSchema = z
    .object({
    id: z.string(),
    object: z.literal('payment_intent'),
    metadata: CheckoutSessionMetadataSchema,
})
    .strip();
export const CheckoutSessionSchema = z.object({
    id: z.string(),
    object: z.literal('checkout.session'),
    customer_details: z.object({
        email: z
            .string()
            .nullable()
            .catch(() => null),
    }),
    payment_intent: z.string(),
});
