/**
 * Do not edit ./generatedTypes and ./generatedEnums files directly.
 * They are auto generated by kysely-prisma.
 */
var _a;
import { Kysely as NativeKysely, } from 'kysely';
export * from './augmented-types.js';
export * as Model from './generated-types.js';
export * from './generated-enums.js';
export * from './selectable-types.js';
export { sql } from 'kysely';
export * from './helpers.js';
export class Kysely extends NativeKysely {
    static #identifier = Symbol();
    #identity() {
        return (_a).#identifier;
    }
}
_a = Kysely;
