export const PASSES_ROUTES = {
    root: () => '/passes',
    activities: () => ({
        root: () => `${PASSES_ROUTES.root()}/activities`,
        options: (id) => `${PASSES_ROUTES.activities().root()}/${id}`,
    }),
    view: (passId) => `${PASSES_ROUTES.root()}/${passId}`,
    selectAssignee: (typeId, age) => `${PASSES_ROUTES.root()}/${typeId}/${age}/select-assignee`,
    selectAssigneeSingle: (rateData, activityName) => {
        const searchParams = new URLSearchParams({
            rateData,
            activityName,
        });
        return `${PASSES_ROUTES.root()}/select-assignee-single?${searchParams.toString()}`;
    },
    review: () => ({
        root: () => `${PASSES_ROUTES.root()}/review`,
        paymentId: (paymentId) => `${PASSES_ROUTES.review().root()}/${paymentId}`,
    }),
    confirmation: () => ({
        root: () => `${PASSES_ROUTES.root()}/confirmation`,
        paymentId: (paymentId) => `${PASSES_ROUTES.confirmation().root()}/${paymentId}`,
    }),
    myPasses: () => ({
        root: () => `${PASSES_ROUTES.root()}/my-passes`,
        paymentId: (paymentId) => `${PASSES_ROUTES.myPasses().root()}/${paymentId}`,
        seniorCitizenPass: () => ({
            root: () => `${PASSES_ROUTES.myPasses().root()}/senior-citizen-pass`,
            gym: () => `${PASSES_ROUTES.myPasses().seniorCitizenPass().root()}/gym`,
            swim: () => `${PASSES_ROUTES.myPasses().seniorCitizenPass().root()}/swim`,
        }),
    }),
    paymentLink: () => ({
        root: () => `${PASSES_ROUTES.root()}/payment-link`,
        paymentLinkId: (paymentLinkId) => `${PASSES_ROUTES.paymentLink().root()}/${paymentLinkId}`,
    }),
};
