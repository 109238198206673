import { UrlValidator } from '@opengovsg/starter-kitty-validators';
import { z } from 'zod';
import { getBaseUrl } from '../utilities/getBaseUrl.js';
const baseUrl = getBaseUrl();
const validator = new UrlValidator({
    baseOrigin: new URL(baseUrl).origin,
    whitelist: {
        protocols: ['http', 'https'],
        hosts: [new URL(baseUrl).host],
    },
});
export const createRedirectUrlSchema = (fallbackUrl) => z
    .string()
    .optional()
    .default(fallbackUrl)
    .transform((url, ctx) => {
    try {
        return validator.parse(url).pathname;
    }
    catch (error) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: error.message,
        });
        return z.NEVER;
    }
})
    .catch(fallbackUrl);
