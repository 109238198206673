import { z } from 'zod';
// Coerces a string to true if it's "true", false if "false".
const coerceBoolean = z
    .string()
    // only allow "true" or "false" or empty string
    .refine((s) => s === 'true' || s === 'false' || s === '')
    // transform to boolean
    .transform((s) => s === 'true')
    // make sure transform worked
    .pipe(z.boolean());
/**
 * Specify your client-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars. To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
const client = z.object({
    NEXT_PUBLIC_ENVIRONMENT: z
        .enum(['development', 'staging', 'test', 'production', 'uat'])
        .optional(),
    NEXT_PUBLIC_APP_NAME: z.string().default('ActiveSG'),
    NEXT_PUBLIC_APP_VERSION: z.string(),
    NEXT_PUBLIC_APP_URL: z.string().url().optional(),
    // This env variable is needed so we can get the MEMBER_URL regradless of whether we are in `admin` or `member` app
    NEXT_PUBLIC_MEMBER_APP_URL: z.string(),
    NEXT_PUBLIC_CLOUDFLARE_SITEKEY: z.string(),
    NEXT_PUBLIC_DATADOG_APPLICATION_ID: z.string(),
    NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: z.string(),
    NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY: z.string(),
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string(),
    NEXT_PUBLIC_ENABLE_STORAGE: coerceBoolean.default('false'),
    NEXT_PUBLIC_ENABLE_SGID: coerceBoolean.default('false'),
    NEXT_PUBLIC_ENABLE_TWILIO: coerceBoolean.default('false'),
    NEXT_PUBLIC_ENABLE_POSTMAN: coerceBoolean.default('false'),
});
/** Feature flags */
const baseSgidSchema = z.object({
    SGID_CLIENT_ID: z.string().optional(),
    SGID_CLIENT_SECRET: z.string().optional(),
    // Remember to set SGID redirect URI in SGID dev portal.
    SGID_REDIRECT_URI: z.union([z.string().url(), z.string()]).optional(),
    SGID_PRIVATE_KEY: z.string().optional(),
    SGID_ENDPOINT: z.string().optional(),
});
const sgidServerSchema = z.discriminatedUnion('NEXT_PUBLIC_ENABLE_SGID', [
    baseSgidSchema.extend({
        NEXT_PUBLIC_ENABLE_SGID: z.literal(true),
        // Add required keys if flag is enabled.
        SGID_CLIENT_ID: z.string().min(1),
        SGID_CLIENT_SECRET: z.string().min(1),
        SGID_PRIVATE_KEY: z.string().min(1),
        SGID_REDIRECT_URI: z.string().url().optional(),
        SGID_ENDPOINT: z.string().optional(),
    }),
    baseSgidSchema.extend({
        NEXT_PUBLIC_ENABLE_SGID: z.literal(false),
    }),
]);
const baseTwilioSchema = z.object({
    TWILIO_ACCOUNT_SID: z.string().optional(),
    TWILIO_AUTH_TOKEN: z.string().optional(),
    TWILIO_MESSAGING_SERVICE_SID: z.string().optional(),
});
const twilioServerSchema = z.discriminatedUnion('NEXT_PUBLIC_ENABLE_TWILIO', [
    baseTwilioSchema.extend({
        NEXT_PUBLIC_ENABLE_TWILIO: z.literal(true),
        // Add required keys if flag is enabled.
        TWILIO_ACCOUNT_SID: z.string().min(1),
        TWILIO_AUTH_TOKEN: z.string().min(1),
        TWILIO_MESSAGING_SERVICE_SID: z.string().min(1),
    }),
    baseTwilioSchema.extend({
        NEXT_PUBLIC_ENABLE_TWILIO: z.literal(false),
    }),
]);
const basePostmanSchema = z.object({
    POSTMAN_API_ENDPOINT: z.string().min(1).catch('https://postman.gov.sg'),
    POSTMAN_API_KEY: z.string().optional(),
    POSTMAN_CAMPAIGN_ID: z.string().optional(),
});
const postmanServerSchema = z.discriminatedUnion('NEXT_PUBLIC_ENABLE_POSTMAN', [
    basePostmanSchema.extend({
        NEXT_PUBLIC_ENABLE_POSTMAN: z.literal(true),
        POSTMAN_API_ENDPOINT: z.string(),
        POSTMAN_API_KEY: z.string(),
        POSTMAN_CAMPAIGN_ID: z.string(),
    }),
    basePostmanSchema.extend({
        NEXT_PUBLIC_ENABLE_POSTMAN: z.literal(false),
    }),
]);
/**
 * Specify your server-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars.
 */
const server = z
    .object({
    LOG_LEVEL: z
        .enum([
        'silent',
        'debug',
        'info',
        'notice',
        'warning',
        'error',
        'critical',
        'alert',
        'emergency',
    ])
        .default('info'),
    NODE_ENV: z.enum(['development', 'test', 'production']),
    DATABASE_URL: z.string().url(),
    DATABASE_READ_ONLY_URL: z
        .string()
        .url()
        .optional()
        .superRefine((url, ctx) => {
        const isAws = process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ||
            process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ||
            process.env.NEXT_PUBLIC_ENVIRONMENT === 'uat';
        // cannot be empty when deployed to AWS environments
        if (isAws && !url) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['DATABASE_READ_ONLY_URL'],
                message: 'Read-only database URL is missing',
            });
        }
    }),
    CACHE_ENABLE_TLS: coerceBoolean.default('false'),
    CACHE_HOSTNAME: z.string(),
    CACHE_PORT: z.coerce.number().default(6379),
    CACHE_USERNAME: z.string().optional(),
    CACHE_PASSWORD: z.string().optional(),
    DURABLE_CACHE_ENABLE_TLS: coerceBoolean.default('false'),
    DURABLE_CACHE_HOSTNAME: z.string().optional(),
    DURABLE_CACHE_PORT: z.coerce.number().default(6379),
    DURABLE_CACHE_USERNAME: z.string().optional(),
    DURABLE_CACHE_PASSWORD: z.string().optional(),
    GOOGLE_MAPS_API_KEY: z.string().optional(),
    SESSION_SECRET: z.string().min(32),
    UPCOMING_SESSION_SECRET: z.string().min(32).optional(),
    QR_CODE_PUBLIC_KEY: z.string(),
    QR_CODE_PRIVATE_KEY: z.string(),
    OTP_EXPIRY_MS: z.coerce.number().positive().optional().default(600000),
    /** @deprecated */
    ONEMAP_EMAIL: z.string(),
    /** @deprecated */
    ONEMAP_PASSWORD: z.string(),
    CLOUDFLARE_SECRET_KEY: z.string(),
    SES_IAM_ACCESS_KEY_ID: z.string().optional(),
    SES_IAM_SECRET_ACCESS_KEY: z.string().optional(),
    /** Must be a verified identity, or email sending may fail */
    SES_FROM_EMAIL: z.string().email(),
    STRIPE_SECRET_KEY: z.string(),
    STRIPE_WEBHOOK_SECRET: z.string(),
})
    // Add on schemas as needed that requires conditional validation.
    .merge(baseSgidSchema)
    .merge(baseTwilioSchema)
    .merge(basePostmanSchema)
    .merge(client)
    // Add on refinements as needed for conditional environment variables
    // .superRefine((val, ctx) => ...)
    .superRefine((val, ctx) => {
    if (!val.NEXT_PUBLIC_ENVIRONMENT ||
        !['production', 'uat', 'staging'].includes(val.NEXT_PUBLIC_ENVIRONMENT)) {
        return;
    }
    if (!val.DURABLE_CACHE_HOSTNAME) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['DURABLE_CACHE_HOSTNAME'],
            message: 'Durable cache hostname is missing',
        });
    }
})
    .superRefine((val, ctx) => {
    const parse = sgidServerSchema.safeParse(val);
    if (!parse.success) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['NEXT_PUBLIC_ENABLE_SGID'],
            message: 'SGID environment variables are missing',
        });
        parse.error.issues.forEach((issue) => {
            ctx.addIssue(issue);
        });
    }
})
    .superRefine((val, ctx) => {
    const parse = twilioServerSchema.safeParse(val);
    if (!parse.success) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['NEXT_PUBLIC_ENABLE_TWILIO'],
            message: 'Twilio environment variables are missing',
        });
        parse.error.issues.forEach((issue) => {
            ctx.addIssue(issue);
        });
    }
})
    .superRefine((val, ctx) => {
    const parse = postmanServerSchema.safeParse(val);
    if (!parse.success) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['NEXT_PUBLIC_ENABLE_POSTMAN'],
            message: 'Postman environment variables are missing',
        });
        parse.error.issues.forEach((issue) => {
            ctx.addIssue(issue);
        });
    }
});
/**
 * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
 * middlewares) or client-side so we need to destruct manually.
 * Intellisense should work due to inference.
 *
 * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
 */
const processEnv = {
    // Server-side env vars
    LOG_LEVEL: process.env.LOG_LEVEL,
    NODE_ENV: process.env.NODE_ENV,
    DATABASE_URL: process.env.DATABASE_URL,
    DATABASE_READ_ONLY_URL: process.env.DATABASE_READ_ONLY_URL,
    CACHE_ENABLE_TLS: process.env.CACHE_ENABLE_TLS,
    CACHE_HOSTNAME: process.env.CACHE_HOSTNAME,
    CACHE_PORT: process.env.CACHE_PORT,
    CACHE_USERNAME: process.env.CACHE_USERNAME,
    CACHE_PASSWORD: process.env.CACHE_PASSWORD,
    DURABLE_CACHE_ENABLE_TLS: process.env.DURABLE_CACHE_ENABLE_TLS,
    DURABLE_CACHE_HOSTNAME: process.env.DURABLE_CACHE_HOSTNAME,
    DURABLE_CACHE_PORT: process.env.DURABLE_CACHE_PORT,
    DURABLE_CACHE_USERNAME: process.env.DURABLE_CACHE_USERNAME,
    DURABLE_CACHE_PASSWORD: process.env.DURABLE_CACHE_PASSWORD,
    SESSION_SECRET: process.env.SESSION_SECRET,
    UPCOMING_SESSION_SECRET: process.env.UPCOMING_SESSION_SECRET,
    QR_CODE_PRIVATE_KEY: process.env.QR_CODE_PRIVATE_KEY,
    QR_CODE_PUBLIC_KEY: process.env.QR_CODE_PUBLIC_KEY,
    OTP_EXPIRY_MS: process.env.OTP_EXPIRY_MS,
    SGID_CLIENT_ID: process.env.SGID_CLIENT_ID,
    SGID_CLIENT_SECRET: process.env.SGID_CLIENT_SECRET,
    SGID_PRIVATE_KEY: process.env.SGID_PRIVATE_KEY,
    SGID_REDIRECT_URI: process.env.SGID_REDIRECT_URI,
    SGID_ENDPOINT: process.env.SGID_ENDPOINT,
    TWILIO_ACCOUNT_SID: process.env.TWILIO_ACCOUNT_SID,
    TWILIO_AUTH_TOKEN: process.env.TWILIO_AUTH_TOKEN,
    TWILIO_MESSAGING_SERVICE_SID: process.env.TWILIO_MESSAGING_SERVICE_SID,
    POSTMAN_API_ENDPOINT: process.env.POSTMAN_API_ENDPOINT,
    POSTMAN_API_KEY: process.env.POSTMAN_API_KEY,
    POSTMAN_CAMPAIGN_ID: process.env.POSTMAN_CAMPAIGN_ID,
    ONEMAP_EMAIL: process.env.ONEMAP_EMAIL,
    ONEMAP_PASSWORD: process.env.ONEMAP_PASSWORD,
    CLOUDFLARE_SECRET_KEY: process.env.CLOUDFLARE_SECRET_KEY,
    SES_IAM_ACCESS_KEY_ID: process.env.SES_IAM_ACCESS_KEY_ID,
    SES_IAM_SECRET_ACCESS_KEY: process.env.SES_IAM_SECRET_ACCESS_KEY,
    SES_FROM_EMAIL: process.env.SES_FROM_EMAIL,
    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
    STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
    GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
    // Client-side env vars
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    NEXT_PUBLIC_APP_NAME: process.env.NEXT_PUBLIC_APP_NAME,
    NEXT_PUBLIC_APP_VERSION: process.env.NEXT_PUBLIC_APP_VERSION,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    NEXT_PUBLIC_MEMBER_APP_URL: process.env.NEXT_PUBLIC_MEMBER_APP_URL,
    NEXT_PUBLIC_CLOUDFLARE_SITEKEY: process.env.NEXT_PUBLIC_CLOUDFLARE_SITEKEY,
    NEXT_PUBLIC_DATADOG_APPLICATION_ID: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    NEXT_PUBLIC_ENABLE_STORAGE: process.env.NEXT_PUBLIC_ENABLE_STORAGE,
    NEXT_PUBLIC_ENABLE_SGID: process.env.NEXT_PUBLIC_ENABLE_SGID,
    NEXT_PUBLIC_ENABLE_TWILIO: process.env.NEXT_PUBLIC_ENABLE_TWILIO,
    NEXT_PUBLIC_ENABLE_POSTMAN: process.env.NEXT_PUBLIC_ENABLE_POSTMAN,
};
// Don't touch the part below
// --------------------------
/** @typedef {z.input<typeof server>} MergedInput */
/** @typedef {z.infer<typeof server>} MergedOutput */
/** @typedef {z.SafeParseReturnType<MergedInput, MergedOutput>} MergedSafeParseReturn */
// @ts-expect-error Types are wonky from refinement
let env = /** @type {MergedOutput} */ (process.env);
if (!process.env.SKIP_ENV_VALIDATION) {
    const isBuild = !!process.env.BUILD_MODE;
    const isServer = typeof window === 'undefined';
    const parsed = /** @type {MergedSafeParseReturn} */ (isServer && !isBuild
        ? server.safeParse(processEnv) // on server we can validate all env vars
        : client.safeParse(processEnv) // on client we can only validate the ones that are exposed
    );
    if (!parsed.success) {
        console.error('❌ Invalid environment variables:', parsed.error.flatten().fieldErrors);
        throw new Error('Invalid environment variables');
    }
    env = new Proxy(parsed.data, {
        get(target, prop) {
            if (typeof prop !== 'string')
                return undefined;
            // Throw a descriptive error if a server-side env var is accessed on the client
            // Otherwise it would just be returning `undefined` and be annoying to debug
            if (!isServer && !isBuild && !prop.startsWith('NEXT_PUBLIC_'))
                throw new Error(process.env.NODE_ENV === 'production'
                    ? '❌ Attempted to access a server-side environment variable on the client'
                    : `❌ Attempted to access server-side environment variable '${prop}' on the client`);
            return target[ /** @type {keyof typeof target} */(prop)];
        },
    });
}
else if (process.env.STORYBOOK) {
    // See: https://github.com/storybookjs/storybook/issues/17336
    // eslint-disable-next-line no-restricted-syntax
    env = JSON.parse(process.env.STORYBOOK_ENVIRONMENT ?? '{}');
}
export { env };
