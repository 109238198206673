import { LocalDate } from 'tz-local-date';
import { z } from 'zod';
import { TIMEZONE } from '@activesg/constants';
import { dayjs } from './dayjs/index.js';
export var Day;
(function (Day) {
    Day["MON"] = "MON";
    Day["TUE"] = "TUE";
    Day["WED"] = "WED";
    Day["THU"] = "THU";
    Day["FRI"] = "FRI";
    Day["SAT"] = "SAT";
    Day["SUN"] = "SUN";
})(Day || (Day = {}));
const dayOfWeekSchema = z.nativeEnum(Day);
export const validateDayOfWeek = (dayOfWeek) => {
    const result = dayOfWeekSchema.safeParse(dayOfWeek.toUpperCase());
    if (!result.success) {
        return null;
    }
    return result.data;
};
export const isBefore = (reference) => (date) => dayjs(date).tz().isBefore(dayjs(reference).tz(), 'day');
export const isBeforeToday = (date) => dayjs(date).tz().isBefore(dayjs(new Date()).tz(), 'day');
export const isAfter = (reference) => (date) => dayjs(date).tz().isAfter(dayjs(reference).tz(), 'day');
export const isAfterToday = (date) => dayjs(date).tz().isAfter(dayjs(new Date()).tz(), 'day');
export const ld = new LocalDate(TIMEZONE, new Date());
export const toDay = (day) => {
    switch (day) {
        case 'Monday':
            return Day.MON;
        case 'Tuesday':
            return Day.TUE;
        case 'Wednesday':
            return Day.WED;
        case 'Thursday':
            return Day.THU;
        case 'Friday':
            return Day.FRI;
        case 'Saturday':
            return Day.SAT;
        case 'Sunday':
            return Day.SUN;
        default:
            day;
            throw new Error('Invalid path');
    }
};
export function calculateAge(dob) {
    if (!dob)
        return {};
    const today = new Date();
    const dobDate = dayjs(dob).tz();
    const year = Math.abs(dobDate.diff(today, 'year'));
    const month = Math.abs(dobDate.diff(today, 'month')) - year * 12;
    return { year, month };
}
export function calculateYearAge(dob, reference) {
    const dateReferenceYear = ld.getComponents(reference ?? new Date()).year;
    const dobYear = ld.getComponents(dob).year;
    return Math.abs(dateReferenceYear - dobYear);
}
export function padMonthWithZero(month) {
    return month.toString().padStart(2, '0');
}
export function min(first, ...rest) {
    return rest.reduce((acc, date) => (date < acc ? date : acc), first);
}
export function max(first, ...rest) {
    return rest.reduce((acc, date) => (date > acc ? date : acc), first);
}
export function addDays(date, days) {
    return new Date(date.valueOf() + days * 24 * 60 * 60 * 1000);
}
export function addMonthsNoRollover(date, months) {
    const currentDate = date.getUTCDate();
    const currentMonth = date.getUTCMonth();
    const transformedDate = new Date(date);
    transformedDate.setUTCMonth(currentMonth + months, currentDate);
    if (transformedDate.getUTCDate() !== currentDate) {
        transformedDate.setUTCDate(1);
        return new Date(transformedDate.valueOf() - 86400 * 1000);
    }
    return transformedDate;
}
export const msToTime = (durationInMs) => {
    const milliseconds = Math.floor((durationInMs % 1000) / 100);
    const seconds = Math.floor((durationInMs / 1000) % 60);
    const minutes = Math.floor((durationInMs / (1000 * 60)) % 60);
    const hours = Math.floor((durationInMs / (1000 * 60 * 60)) % 24);
    return {
        hours,
        minutes,
        seconds,
        milliseconds,
    };
};
