import { useCallback, useState } from 'react';
import { Box } from '@chakra-ui/react';
import QR from 'qrcode';
export const QRCode = ({ payload, ...props }) => {
    const [size, setSize] = useState();
    const containerRefCallback = useCallback((element) => {
        setSize(Math.min(element.clientHeight, element.clientWidth));
    }, [setSize]);
    const canvasRefCallback = useCallback(async (element) => {
        await QR.toCanvas(element, payload, {
            scale: 4,
            margin: 1,
            color: {
                dark: '#000000FF',
                light: '#FFFFFFFF',
            },
            errorCorrectionLevel: 'M',
            width: size ? size : 0,
        });
    }, [size, payload]);
    return (<Box ref={containerRefCallback} {...props}>
      <canvas ref={canvasRefCallback}/>
    </Box>);
};
